* {
  margin: 0;
  padding: 0px;
  box-sizing: border-box;
}

:root {
  --branco: #FFF;
  --black: #000000;
  --cinza-100: #AFAFAF;
  --cinza-200: #525252;
  --cinza-700: #15171b;
  --cinza-900: #111113;
  --azul-900: #1fa4e5;
  --amarelo-500: #FFBE16;
}

@media (max-width: 1080px) {
  html {
    font-size: 93.75%;
  }
}

@media (max-width: 720px) {
  html {
    font-size: 87.5%;
  }
}

@media (max-width: 350px) {
  html {
    font-size: 51.5%;
  }
}

body {
  background: var(--branco);
  background: var(--cinza-200);
  background-color: var(--black);
}

body,
input,
textarea,
select,
button {
  font: 400 1rem 'Poppins', sans-serif;
}

button {
  cursor: pointer;
}

a {
  color: inherit;
  text-decoration: none;
}